/*Theme    : assan
 * Author  : Design_mylife
 * Version : V2.6
 * 
 */



 $( window ).resize(function() {
    $(".navbar-collapse").css({ maxHeight: $(window).height() - $(".navbar-header").height() + "px" });
});




//owl carousel for news
$(document).ready(function () {

    $("#news-carousel").owlCarousel({
        // Most important owl features
        items: 2,
        itemsCustom: false,
        itemsDesktop: [1199, 2],
        itemsDesktopSmall: [980, 2],
        itemsTablet: [768, 2],
        itemsTabletSmall: false,
        itemsMobile: [479, 1],
        singleItem: false,
        startDragging: true,
        autoPlay: 4000
    });

});



//owl carousel for testimonials
$(document).ready(function () {

    $("#testi-carousel").owlCarousel({
    loop:true,
    margin:0,
    nav:false,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
});

});
//featured work carousel slider

$(document).ready(function () {

    $("#list-carousel").owlCarousel({
        loop:true,
        margin:0,
        nav:false,
        items:1,
        autoplay: true,
        autoplayTimeout: 4000
    });
});



/*========tooltip and popovers====*/
$(document).ready(function () {
$("[data-toggle=popover]").popover();

$("[data-toggle=tooltip]").tooltip();
});





///ON ADD WATCH FORM NEED TO SET BRAND NAME INPUT - GRAB IT FROM THE BRAND DROP DOWN
$(document).ready(function () {
    $('#brand_id').on('change', function(){
        brand_name = $( "#brand_id option:selected" ).text();
        $("#brand").val(brand_name);
    });

    // Brand Country drop down // triggers modal if they select -Not Listed-
    $('#brand_country').on('change', function(){
       if($(this).val() == '-Not Listed-') {
           // Show Add Country Modal
            $("#addCountry").modal();
        }
    });

    $('#submit_addCountry').on('click', function(){
            var country = $('#add-country').val();
            if (country) {
                $("#addCountry").modal('toggle');
                $('select#brand_country').append(jQuery("<option></option>").attr("value", country).text(country).attr("selected","selected"));
            } else {
                $("#addCountry").modal('toggle');
            }
    });
           


    // edit watch photos
    $('.editPhotoSelector').on('click', function(){
        var asset_id = $(this).attr('data-assetId');
        var fid = $(this).attr('data-id');
        var fname = $(this).attr('data-fname');
        var submit_type = $(this).attr('data-submit');

            $.ajax({
              method: "POST",
              url: '',
              data: { 'submit_deletePhoto': true, 'submit_type' : submit_type, 'global': true, 'asset_id': asset_id, 'fname': fname, 'fid': fid},
              dataType: "HTML"
            }).done(function(resp){
              if (resp == 'success') {
                    $("#modal-error").html('');
                    $("#photoid_"+fid).hide();
                } else {
                    $("#modal-error").html('Sorry there was a problem');
                    //$("#modal-error").html(resp);
                }
            });
    });

    // submit_editPhotos refresh window
    $('#submit_editPhotos').on('click', function() {
       // refresh page to remove removed images from main page
       window.location.reload();
    }); // end submit_editPhotos


    $(".clickable-row").on('click', function() {
        window.location = $(this).data("href");
    });


    ///split up order_titles 
    function makeUL(array) {
    // Create the list element:
    var list = document.createElement('ul');

    for (var i = 0; i < array.length; i++) {
        if (array[i] != '') {
            // Create the list item:
            var item = document.createElement('li');

            // Set its contents:
            item.appendChild(document.createTextNode(array[i]));

            // Add it to the list:
            list.appendChild(item);
        }
    }

    // Finally, return the constructed list:
    return list;
}


    $(".order_titlez").each(function(){
        order_titlez = $(this).text();
        new_order_titlez = order_titlez.split('|| ');
        new_titles = makeUL(new_order_titlez);
        $(this).html(new_titles);
    });

    $(".notifications").on('click', function(){
        var href = $(this).data("href");
        var note_id = $(this).data('note_id');
        var note_asset_id = $(this).data('note_asset_id');
        transfer({'href': href, 'note_id': note_id, 'submit_deleteNotification': true, 'global': true});
    });


    // LAZY LOAD IMAGES
    $('.lazy').Lazy({
        // your configuration goes here
        enableThrottle: true,
        throttle: 100,
        scrollDirection: 'vertical',
        effect: 'fadeIn',
        effectTime: 400,
        visibleOnly: true,
        onError: function(element) {
            console.log('error loading ' + element.data('src'));
        }
    }); 


    // MAKE STARS FOR STARRED ELEMENTS
    $(".stars").each(function(){
        let star = $(this).data('stars'); // grade A,B,C,F
        let starsArr = ['F', 'D', 'C', 'B', 'A'];
        let starPosition = starsArr.indexOf(star);
        let html = '';

        for (var i = 0; i <= 4; i++) {
           if (i <= starPosition) {
            html += '<i class="glyphicon glyphicon-star" style="color: orange;"></i>';
           } else {
             html += '<i class="glyphicon glyphicon-star-empty" style="color: #d0d0d0;"></i>';
           }
        }
        $(this).html(html);
    });

    /// RECENT HISTORY FEED

    if ($("#recent-history-feed")) {
    var recentItems = getCookie('itmHist');
    if (recentItems) {
        str = '';
        //for (var i = 0; i <= recentItems.length - 1; i++) {
        for (var i = 0; i <= recentItems.length -1; i++) {
            if (i <= 3) {
                str +=` 
                <div class="col-sm-6 recent-history-feed-card">
                    <div class="recent-history-feed-img"><a href="` + recentItems[i].ui_url + `"><img src="` + recentItems[i].ui_img + `" alt="" class="img-responsive"></a>
                    </div>
                    <div class="recent-history-feed-title"><h5> <a href="` + recentItems[i].ui_url + `" class="text-dark">` + recentItems[i].ui_title + `</a></h5>
                    </div>
                    <div class="recent-history-feed-price small text-muted">$` + recentItems[i].sale_amount + `</div>
                </div>`;
            } else {
                break;
            }
            
        }
        $("#recent-history-feed").append(str);
        $("#recent-history-feed-section").removeClass('hide');
    }
}

if ($("#recent-history")) {
    var recentItems = getCookie('itmHist');
    if (recentItems) {
        str = '';
        //for (var i = 0; i <= recentItems.length - 1; i++) {
        for (var i = 0; i <= recentItems.length -1; i++) {
            if (i <= 5) {
            str +=` 
            <div class="col-sm-2">
            <div class="recent-history-img"> <a href="` + recentItems[i].ui_url + `"><img src="` + recentItems[i].ui_img + `" alt="" class="img-responsive"></a>
            <div class="recent-history-title">
              <h5> <a href="` + recentItems[i].ui_url + `" class="text-dark">` + recentItems[i].ui_title + `</a></h5>
            </div>
            <div class="recent-history-price small text-muted">$` + recentItems[i].sale_amount + `</div>
            </div>
          </div>`;
            } else {
                break;
            }
        }
        $("#recent-history").append(str);
        $("#recent-history-section").removeClass('hide');
    }
}

if (user_following) {
    $(".follow").each(function(index){
        if (user_following) {
           for (var i = 0; i < user_following.length; i++) {

               if ( (parseInt(user_following[i].user_follow_asset_type) == $(this).data('user_follow_asset_type') ) && (parseInt(user_following[i].user_follow_asset_id) == $(this).data('user_follow_asset_id')) ) {
          
                    $(this).removeClass('btn-primary');
                    $(this).addClass('btn-secondary');
                    $(this).html('Unfollow');
                    $(this).val('submit_removeFollow');
                    break; // STOP - IT IS IN THE ARRAY

               } else {
                   
                    $(this).removeClass('btn-secondary');
                    $(this).addClass('btn-primary');            
                    $(this).html('Follow');
                    $(this).val('submit_addFollow');
               }
           }
        } else { // not following anything
            $(this).addClass('btn-primary');            
            $(this).html('Follow');
            $(this).val('submit_addFollow');
        }
        
    });


    // FOLLOW BUTTONS FOR FOLLOWING TAGS, BRANDS, PROFILES ETC
    $(".follow").on('click', function(){
            var self = event.target;

            var user_follow_asset_id = $(this).data('user_follow_asset_id');
            var user_follow_asset_type = $(this).data('user_follow_asset_type');
            var tag_slug = $(this).data('tag_slug');     
            user_follow_action = self.value;


            if (user_follow_action == 'submit_addFollow') {
                $.post('/', {'ajax': true, [user_follow_action] :true, 'user_follow_asset_id': user_follow_asset_id, 'tag_slug': tag_slug ,'user_follow_asset_type': user_follow_asset_type})
                .done( function(data) {
                    alert('Awesome! Added to Your Feed!');
                    $(self).html('Unfollow');
                   $(self).removeClass('btn-primary');
                    $(self).addClass('btn-secondary');
                    self.value = 'submit_removeFollow';
                  }
                )
            } else if (user_follow_action == 'submit_removeFollow') {
                $.post('/', {'ajax': true, [user_follow_action] :true, 'user_follow_asset_id': user_follow_asset_id, 'tag_slug': tag_slug ,'user_follow_asset_type': user_follow_asset_type})
                .done( function(data) {
                    alert('Removed From Your Feed!!!');
                    $(self).html('Follow');
                    $(self).removeClass('btn-secondary');
                    $(self).addClass('btn-primary');
                    self.value = 'submit_addFollow'

                    // need to remove the item from var user_following
                    for (var i = 0; i < user_following.length; i++) {

                       if ( (parseInt(user_following[i].user_follow_asset_type) == user_follow_asset_type ) && (parseInt(user_following[i].user_follow_asset_id) == user_follow_asset_id ) ) {
                  
                            user_following.splice(i, 1);

                            break; // STOP - IT IS IN THE ARRAY
                        }
                    }

                })
            }        
    });
} // END if USER_FOLLOWING SET



}); // END ON LOAD
 