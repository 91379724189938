
/*   CART STUFF */
//testobj to look at the ajax data obj outside the function
// Mini cart template is referenced in cart.js and HEADER.php
var testobj = '';

function miniCart(resp) {
    var items = '';
    var prod = '';
    data = JSON.parse(resp);
    items += "<ul>";
    var cart_total = 0;

    for (var i = 0; i < data.length; i++) {
       
        items += '<li><a href="' + data[i]['prod_url'] + '"><img src="' + data[i]['prod_img'] + '" class="mini-cart-img" / ><div class="mini-cart-disc">' ;
        items += data[i]['prod_name'] + '</div><div class="mini-cart-price">$' + data[i]['item_total'] + '</div></a></li>';
        cart_total = parseFloat(cart_total) + parseFloat(data[i]['item_total']);
    }
    items += '<div class="mini-cart-total">Total: $' + cart_total + '</div>';
    items += '</ul><a id="mini-cart-proceed" class="btn-primary btn-block text-center" href="/cart"> Proceed to cart</a>';
    jQuery("#no-items-in-mini-cart").html(items);

}

function checkToProceed(resp, item_quantity_available, sku) {
    data = JSON.parse(resp);


    for (var i = 0; i < data.length; i++) {
        if (data[i].sku == sku) {
            if (item_quantity_available == data[i].quantity) {
                return true;
            } else {
                return false;
            }
        }
    }

}


jQuery('.add-to-cart').click(function(e) {
    e.preventDefault();
    
    jQuery(this).addClass('loading');
    jQuery(this).html('Adding... <i class="fas fa-spinner"></i>');

    var item_price = jQuery(this).attr('data-price');
    var item_sku = jQuery(this).attr('data-sku');
    var item_quantity = jQuery(this).attr('data-quantity');
    var item_quantity_available = jQuery(this).attr('data-quantity-available');
    var item_name = jQuery(this).attr('data-prod-name');
    var item_url = jQuery(this).attr('data-prod-url');
    var item_img = jQuery(this).attr('data-prod-img');
    
    jQuery.ajax({
        method: 'POST',
        url: '',
        data: { 
            global_cart: true,
            addToCart: true,
            quantity: item_quantity,
            quantity_available: item_quantity_available,
            sku: item_sku,
            price: item_price,
            prod_name: item_name,
            prod_url: item_url,
            prod_img: 'https://s3.amazonaws.com/uploads.watch/userwatches/' + item_sku + '/t-' + item_img
        },
        success: function(result) {
            //console.log(result);
            if (result == '"quantity not available"') {
                alert(' The Quantity Requested is not Available');
            } else {
                jQuery('#added-to-cart').slideDown().delay(1500).slideUp();;
                jQuery('.add-to-cart').delay(10000).removeClass('loading');
                jQuery('.add-to-cart').delay(10000).html('Add to Cart <i class="fas fa-shopping-cart"></i>');
                testobj = result;
                miniCart(result);

                if (checkToProceed(result, item_quantity_available, item_sku)) {
                    $("#forsale-block-title").text('Item in Cart');
                    $("#forsale-block-action").html('<a class="btn-primary btn-block text-center" href="/cart"> Proceed to cart</a>');
                } else {
                    $("#proceed_to_cart").show();
                }
            }


        },
        error: function(result) {
            alert('error');
        }
    });
});


// THIS GRABS THE QUANTITY OF THE ITEM
jQuery('.quantity').on('blur', function(e) {
    e.preventDefault();

    var product = jQuery(this).attr('data-prod');
    var quantity = jQuery(this).val();

    jQuery('#prod-' + product).attr('data-quantity', quantity);

});

// Clear Cart
jQuery('.clear-cart').on('click', function(e)   {  
    
    jQuery.ajax({
        method: 'POST',
        url: '',
        data: { 
            global_cart: true,
            clearCart: true,
        }, 
        success: function() {
            jQuery("#cart").html('<tr><th>PRODUCT</th><th>SKU</th><th>QNTY</th><th>ITEM TOTAL</th></tr>');
            jQuery('.clear-cart').hide();
            jQuery("#no-items-in-mini-cart").html('<li> No Items currently in cart </li>');
        },
        error: function() {
             alert('error');
        }
    });
});


/// SHOWS / HIDES CART WHEN HOVERING OVER CART BUTTON
jQuery('#mini-cart').hover(function() {
        jQuery("#no-items-in-mini-cart").toggle();
});

jQuery('.remove_cart_item').on('click', function() {
        //alert("REMOVE ITEM!!");
        var item_sku = jQuery(this).attr('data-prod');
        var item_quantity = jQuery(this).attr('data-prod-quantity');
        var item_total = jQuery(this).attr('data-item-total');
        var cart_total = parseFloat( jQuery("#cart-total").text() );

         jQuery.ajax({
            method: 'POST',
            url: '',
            data: { 
                global_cart: true,
                removeFromCart: true,
                sku: item_sku,
              
            },
            success: function(result) {
                //console.log(result);
                //console.log('remove_cart_item cart.js');

                    // remove from items cart
                    jQuery('#cart-' + item_sku).remove();

                    testobj = result;
                    miniCart(result);

                    cart_total -= item_total;
                    jQuery("#cart-total").text(cart_total);
               

            },
            error: function(result) {
                alert('There was a problem');
                console.log('remove cart item error');
            }
        });

});

// Have to reset the add modal since it is also used for editing 
jQuery("#addAddressModal-modal-btn").on('click', function() {
    //alert("Clear");
    $("#fname").val(null);
    $("#lname").val(null);
    $("#address").val(null);
    $("#addresss2").val(null);
    $("#city").val(null);
    $("#state").val(null);
    $("#zip").val(null);
    $("#address_id").val(null);
    $("#addAddress-title-id").text('Edit Address');
    $("#submit_addAddress").text('Add Address');
    $("#submit_addAddress").attr('name', 'submit_addAddress');
});

jQuery('.edit_address').on('click', function() {
    //alert("EDIT ADDRESSS");
    var address_id = jQuery(this).attr('data-item');

    jQuery.ajax({
        method: 'POST',
        url: '',
        data: { 
            global: true,
            submit_getAddress: true,
            address_id: address_id,
        },
        success: function(result) {
            //console.log(result);
            data = JSON.parse(result);
           
            testobj = result;

            // load up the address modal
            $("#fname").val(data.fname);
            $("#lname").val(data.lname);
            $("#address").val(data.address);
            $("#addresss2").val(data.addresss2);
            $("#city").val(data.city);
            $("#state").val(data.state);
            $("#zip").val(data.zip);
            $("#address_id").val(data.address_id);
            $("#addAddress-title-id").text('Edit Address');
            $("#submit_addAddress").text('Edit Address');
            $("#submit_addAddress").attr('name', 'submit_updateAddress');

            $("#addAddressModal").modal('toggle');
           
        },
        error: function(result) {
            alert('There was a problem');
            console.log('edit address error');
        }
    });
});

jQuery('.add_quantity').on('click', function() {   
    var options = $(this).data("options");
    var new_quant = parseInt(options.quantity)+1;
    var cart_id = options.cart_id;

    if (options.quantity_available >= new_quant) {
        //alert("ADDING!!!" + options.sku + ':' + options.quantity + '=' + new_quant + ' cart_id: ' + cart_id);
        //post sku, quantity, cart_id
        transfer({
            global_cart: true,
            updateQuantity: true,
            sku: options.sku,
            quantity: new_quant,
            cart_id: cart_id
        });



    } else {
        alert("Sorry! - not enough");
        $(this).hide();
    }
});

jQuery('.subtract_quantity').on('click', function() {
    var options = $(this).data("options");
    var new_quant = parseInt(options.quantity)-1;
    var cart_id = options.cart_id;

    if (new_quant > 0) {
        //alert("SUBTRACTING!!!" + options.sku + ':' + options.quantity + '=' + new_quant+ ' cart_id: ' + cart_id);
        transfer({
            global_cart: true,
            updateQuantity: true,
            sku: options.sku,
            quantity: new_quant,
            cart_id: cart_id
        });


    } else {
        alert("Sorry! - not enough");
        $(this).hide();
    }
});

// jQuery('.place_order').on('click', function() {
//     var cart_id = $(".place_order").attr('data-cart-id');
//     var user_id = $(".place_order").attr('data-user-id');

//     jQuery.ajax({
//         method: 'POST',
//         url: '',
//         data: { 
//             global: true,
//             submit_order: true,
//             cart_id: cart_id,
//             user_id: user_id,
//         },
//         success: function(result) {
//             //console.log(result);
//             data = JSON.parse(result);
           
//             testobj = result;

//             $("#order_success").modal('toggle');
//         },
//         error: function(result) {
//             alert('There was a problem');
//             console.log('submit order error');
//             console.log(result);
//         }
//     });
// });