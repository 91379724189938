/// BOOTSTRAP CSS - SITE CSS
import './library/bootstrap/less/bootstrap.less';

import './library/css/site.css';

import './library/css/jquery-ui.min.css';
import './library/owl-carousel/assets/owl.carousel.css';
import './library/owl-carousel/assets/owl.theme.default.css';

import './library/js/bootstrap-tagsinput.css';
/// TAG CSS)

// font - may not work
//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,800;1,300;1,500;1,800&family=Oswald:wght@700&display=swap');

// OWL CAROUSEL
import './library/owl-carousel/owl.carousel.min.js';
// LAZY JQUERY 
import './library/js/jquery.lazy.min.js';
/// SITE RANDOM JS (FLEX SLIDESHOW - OWL CAROUSEL - ETC)


import './library/js/bootstrap-tagsinput.js';
/// TAG JS)


import './library/js/custom.js';
/// SHOPING CART JS
import './library/js/cart.js';


import unique from 'uniq';


//var JQuery = require('jquery');

  //import pin from './Templates/components/PinComponent.vue';
  //import wantHaveAdd from './Templates/components/WantHaveAddComponent.vue';


/// HANDLINE READ MORE SECTIONS
  let RmaxLength = 1350;
  $(".show-read-more").each(function(){
      //console.log('run 1');

      var myStr = $(this).text();

      console.log(myStr.length);

      if($.trim(myStr).length > RmaxLength){
          var newStr = myStr.substring(0, RmaxLength);
          var removedStr = myStr.substring(RmaxLength, $.trim(myStr).length);
          $(this).empty().html(newStr);
          $(this).append(' <a href="javascript:void(0);" class="read-more btn btn-sm btn-secondary">read more...</a>');
          $(this).append('<span class="more-text">' + removedStr + '</span>');
      }
  });
  $(".read-more").click(function(){
      $(this).siblings(".more-text").contents().unwrap();
      $(this).remove();
  });


$('.tags').tagsinput({
  tagClass: 'badge',
  confirmKeys: [13, 44],
  delimiter: ',',
  delimiterRegex:null,
  cancelConfirmKeysOnEmpty:false,
  onTagExists:function(item, $tag) {
    $tag.hide().fadeIn();
  },
  trimValue:true,
  allowDuplicates:false,
  riggerChanget:true
});


//// ROUND UP PRICES
  $(".price").each(function(){
    let price = $(this).text();
      price = parseFloat(price).toFixed(2);
      $(this).text(price);
  });




            /// VH FEE CALCULATOR 
if ($("#fp_sold_price_rcvd").length != 0) {
window.onload = function() {
              [...document.querySelectorAll('.fee_compare_input')].forEach(function(item) {
              item.addEventListener('keyup', calculate_fees);
               });


              var fp_sold_price_rcvd = document.getElementById("fp_sold_price_rcvd").value;
              var fp_ship_price_rcvd = document.getElementById("fp_ship_price_rcvd").value;

              var ebay_flat_fee = 0.30; 
              var ebay_percent = 0.1325;
              var ebay_total_fees = 0;

              var total_amount_rcvd = 0;
              var ebay_fees = 0;

              var vh_fees = 0;
              var vh_profit = 0;

            function calculate_listing_fees() {
                ebay_fees = ebay_percent * total_amount_rcvd;
                ebay_fees = Math.round(ebay_fees * 100) / 100;
              return ebay_fees;
            }  

            function calculate_total_fees() {
              ebay_total_fees = parseFloat(ebay_fees) + parseFloat(ebay_flat_fee);
              return ebay_total_fees;
            }

            
            function calculate_profit() {
              return (Math.round((parseFloat(total_amount_rcvd) - 
                      parseFloat(fp_ship_price_rcvd) -
                      parseFloat(calculate_total_fees())) * 100) / 100);      
            }


            function vh_calculate_total_fees() {

              var cc = parseFloat(vh_cc_percent) * parseFloat(total_amount_rcvd);
              var vh = parseFloat(vh_percent) * parseFloat(total_amount_rcvd);

              return parseFloat((parseFloat(cc) + parseFloat(vh) + parseFloat(vh_cc_fee)) * 100 /100);
            }

            
            function vh_calculate_profit() {
              return parseFloat(total_amount_rcvd) - 
                                  parseFloat(fp_ship_price_rcvd) -
                          parseFloat((vh_fees * 100) / 100) ;      
            }

            function calculate_total_amount_rcvd() {
              total_amount_rcvd = 0.0;
              total_amount_rcvd = (parseFloat(fp_sold_price_rcvd) || 0) + (parseFloat(fp_ship_price_rcvd) || 0);
              if(total_amount_rcvd <= 0) {
                  total_amount_rcvd = 0.0;
                }
                return total_amount_rcvd;
            }

            function calculate_fees() {
              fp_sold_price_rcvd = document.getElementById("fp_sold_price_rcvd").value;
              fp_ship_price_rcvd = document.getElementById("fp_ship_price_rcvd").value;
              // fvf fee                
              total_amount_rcvd = calculate_total_amount_rcvd();

              calculate_listing_fees();
              calculate_total_fees();

              /// EBAY
              document.getElementById('total_amount_rcvd').innerHTML = total_amount_rcvd;
              document.getElementById('ebay_shippin').innerHTML = fp_ship_price_rcvd;
              document.getElementById('ebay_listing_fees').innerHTML = ebay_total_fees.toFixed(2);

              // VALHAULAH
              vh_fees = vh_calculate_total_fees();
              vh_profit = vh_calculate_profit();


              document.getElementById('vh_total_amount_rcvd').innerHTML = total_amount_rcvd;
              document.getElementById('vh_shippin').innerHTML = fp_ship_price_rcvd;

              document.getElementById('vh_listing_fees').innerHTML = vh_fees.toFixed(2);
              document.getElementById('vh_profit').innerHTML = vh_profit.toFixed(2);

              // profit
              var profit = calculate_profit();
              document.getElementById('ebay_profit').innerHTML = profit.toFixed(2);

            }     

            calculate_fees();

  } // END WINDOW LOAD
} // END TOTAL RECEIVED CHECK
//// END FEE CALCULATOR


/// THIS VUE SOLUTION https://forum.vuejs.org/t/passing-props-to-root-instances-in-2-0/244/23


//// PARCEL DOC
//// https://scotch.io/tutorials/using-parcel-in-a-vuejs-app
